import { Paper, Box, Grid, Container, Button } from "@mui/material";
import React from "react";
import TheMap from "./TheMap";
import { useState, useEffect } from "react";
import { useParams, withRouter, useLocation } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";
import MapAndList from "./MapAndList";
import { useStyles } from "../utils/spacing";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Link as MaterialLink } from "@mui/material";
import CableIcon from "@mui/icons-material/Cable";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TrackingInterface = (props) => {
  const [tracks, setTracks] = useState([]);
  const history = useHistory();
  const [trackCount, setTrackCount] = useState(0);
  const classes = useStyles();
  const query = useQuery();
  const [count, setCount] = useState("20");
  const [page, setPage] = useState(0);

  const handleSetCount = (event, newCount) => {
    setCount(newCount);
  };

  const handleSetNextPage = (event) => {
    setPage(page + 1);
    console.log("Set next page");
  }

  const handleSetPreviousPage = (event) => {
    if(page > 0) {
      setPage(page - 1);
      console.log("Set prev page");
    }
  }

  const { hioid } = useParams();

  useEffect(() => {
    if (tracks == []) {
      return;
    }

    let offset = page * count;

    fetch(`/v1/sensors/${hioid}/data?limit=${count}&offset=${offset}`, {
      headers: {
        "X-Api-Key": props.apikey,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setTrackCount(data.length);
        let formattedData = formatData(data);
        console.log({formattedData});
        setTracks(formattedData);
      })
      .catch((error) => {
        console.log(error);
        history.push("/");
      });
  }, [hioid, count, page]);

  const formatData = (input) => {
    return input.map((item) => {
      console.log({item});
      let result = item;
      let data = item.data;
      // TODO unwind the whole list as copies
      if (Array.isArray(data)) {
        return Object.assign(result, data[0]);
      } else {
        if(item["data"] !== undefined && item["data"] !== null && item["data"]["mesr"] !== undefined && item["data"]["mesr"] !== null) {
          let result = item;
          return item["data"]["mesr"].map((mesr)=>{
            Object.assign(result, {"timestamp": mesr["ts"]});
            Object.assign(result, mesr);
            if(item["data"]["rssi"] !== undefined && item["data"]["rssi"] !== null)
            {
              result["rssi_dBm"] = item["data"]["rssi"];
            }
            return result;
          });
        }
        else {
          console.log("No mesr");
          return Object.assign(result, data);
        }
      }
    }).flat();
  };

  const trackSorter = (a, b) => {
    if(a.timestamp === undefined || b.timestamp === undefined){
      return 0;
    }
    if(a.timestamp < b.timestamp) {
      return 1;
    } else {
      return -1;
    }
  }

  const filterCorrupt = (item) => {
    if(item.bch_data_too_corrupt !== undefined && item.bch_data_too_corrupt === true) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Box
      className={classes.collapseMarginPadding}
      style={{ display: "flex", flexGrow: 1 }}
    >
      <Paper style={{ display: "flex", flexGrow: 1 }}>
        <Box p={0} m={0} style={{ flexGrow: 1 }}>
          <Grid container style={{ width: "100%", height: "100%" }}>
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <Box
                className={classes.collapseMarginPadding}
                style={{ height: "100%" }}
              >
                <div className={classes.mapContainer}>
                  <TheMap
                    track={query.get("track")}
                    data={tracks.filter(filterCorrupt).slice(-count)}
                  />
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              p={0}
              m={0}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={classes.collapseMarginPadding}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <strong>{hioid}</strong>
                </Box>
                <ToggleButtonGroup
                  exclusive
                  value={count}
                  onChange={handleSetCount}
                >
                  <ToggleButton value="10" aria-label="left aligned">
                    10
                  </ToggleButton>
                  <ToggleButton value="20" aria-label="centered">
                    20
                  </ToggleButton>
                  <ToggleButton value="50" aria-label="centered">
                    50
                  </ToggleButton>
                  <ToggleButton value="200" aria-label="centered">
                    200
                  </ToggleButton>
                  <ToggleButton value="500" aria-label="centered">
                    500
                  </ToggleButton>
                </ToggleButtonGroup>

                <Button component={Link} to={`/devices/${hioid}/callback`} variant="outlined">
                  <CableIcon></CableIcon>
                  config
                </Button>
              </Box>
              <Box
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  paddingTop: 0,
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={classes.collapseMarginPadding}
              >
                <Button variant="outlined" onClick={handleSetPreviousPage}><ArrowBackIcon/></Button>
                  {page * count} - {(page+1) * count}
                <Button variant="outlined" onClick={handleSetNextPage}><ArrowForwardIcon/></Button>

              </Box>
              <Box
                className={classes.collapseMarginPadding}
                style={{ flexGrow: 1, marginTop: 0, paddingTop: 0 }}
              >
                <div
                  style={{
                    position: "relative",
                    left: 0,
                    top: 0,
                    height: "100%",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div style={{ position: "absolute" }}>
                    <Box className={classes.containerMap}>
                      {tracks.filter(filterCorrupt).sort(trackSorter).slice(-count).map((element, index) => {
                        return (
                          <>
                            <Box className={classes.cell}>
                              <MaterialLink
                                component={Link}
                                to={`/devices/${element.hioid}/tracking?track=${element.id}`}
                              >
                                {new Date(
                                  element.timestamp * 1000
                                ).toUTCString()}
                              </MaterialLink>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default withRouter(TrackingInterface);
