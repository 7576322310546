import React from "react";
import { Marker } from "react-leaflet";
import { useRef, useEffect } from "react";

const MyMarker = (props) => {
  const leafletRef = useRef();
  useEffect(() => {
    if (props.open) {
        console.log("Marker open");
      leafletRef.current.openPopup();
    } else {
      leafletRef.current.closePopup();
    }
  }, [props.open]);
  return <Marker ref={leafletRef} {...props} />;
};

export default MyMarker;
