import React from "react";

import {
  Paper,
  Box,
  Grid,
  Container,
  Button,
  TextField,
  InputBase,
  Input,
  FormControl,
} from "@mui/material";
import TheMap from "./TheMap";
import { useState, useEffect } from "react";
import { useParams, withRouter, useLocation } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";
import MapAndList from "./MapAndList";
import { useStyles } from "../utils/spacing";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Link as MaterialLink } from "@mui/material";
import CableIcon from "@mui/icons-material/Cable";
import { SettingsInputSvideoSharp } from "@material-ui/icons";

const CallbackConfig = (props) => {
  const classes = useStyles();
  const [url, seturl] = useState("");
  const [userdata, setuserdata] = useState("");
  const [headers, setheaders] = useState("");
  const { hioid } = useParams();
  const history = useHistory();

  function fetchCallback() {
    fetch(`/v1/sensors/${hioid}/callback`, {
      headers: {
        "X-Api-Key": props.apikey,
      },
      method: "GET",
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setuserdata(data.user_data);
          setheaders(data.additional_headers);
          seturl(data.url);
        });
      } else if (response.status === 404) {
      } else if (response.status === 401) {
        history.push("/");
      }
    });
  }

  function setCallback() {
    fetch(`/v1/sensors/${hioid}/callback`, {
      headers: {
        "X-Api-Key": props.apikey,
      },
      method: "POST",
      body: JSON.stringify({
        url: url,
        user_data: userdata,
        additional_headers: headers,
      }),
    }).then((response) => {
      if (response.status === 200) {
        fetchCallback();
      } else {
        history.push("/");
      }
    });
  }

  useEffect(() => {
    fetchCallback();
  }, []);

  return (
    <Box className={classes.collapseMarginPadding} style={{ display: "flex" }}>
      <Paper style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <FormControl fullWidth="true">
          <TextField
            id="url"
            value={url}
            label="Callback URL"
            onChange={(e) => seturl(e.target.value)}
            sx={{ m: 1 }}
          />
          <TextField
            id="userdata"
            value={userdata}
            label="user_data"
            onChange={(e) => setuserdata(e.target.value)}
            sx={{ m: 1 }}
          />
          <TextField
            id="headers"
            value={headers}
            label="Additional headers"
            onChange={(e) => setheaders(e.target.value)}
            sx={{ m: 1 }}
            multiline
            rows="3"
          />
        </FormControl>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          m={1}
        >
          <Button variant="outlined" onClick={setCallback}>Set</Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CallbackConfig;
