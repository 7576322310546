import logo from "./logo.svg";
import "./App.css";
import Login from "./components/Login";
import theme from "./components/theme";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/styled-engine";
import { StylesProvider } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/Header";
import SensorList from "./components/DeviceList";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import UsersList from "./components/UsersList";
import DevicesList from "./components/DevicesList";

import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Box } from "@mui/material";

import DeviceList from "./components/DeviceList";
import TheMap from "./components/TheMap";
import TrackingInterface from "./components/TrackingInterface";
import CreateUser from "./components/CreateUser";
import CreateDevice from "./components/CreateDevice";
import BindDevice from "./components/BindDevice";
import CallbackConfig from "./components/CallbackConfig";
import EditDevice from "./components/EditDevice";

function App() {
  const [apikey, setApikey] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div
      className="App"
      display="flex"
      flexDirection="column"
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
        <ThemeProvider theme={theme}>
          <Router>
            <Header apikey={apikey} username={username} password={password}/>
            <Switch>
              <Route exact path="/">
                <Login
                  setapikey={(apiKey) => setApikey(apiKey)}
                  setcredentials={(username, password) => {
                    setUsername(username);
                    setPassword(password);
                  }}
                />
              </Route>
              <Route exact path="/devices">
                {apikey && <DeviceList apikey={apikey} />}
                {username && password  && <DevicesList username={username} password={password} />}
              </Route>
              <Route path="/devices/:hioid/tracking">
                <TrackingInterface apikey={apikey} />
              </Route>
              <Route path="/devices/:hioid/callback">
                <CallbackConfig apikey={apikey} />
              </Route>
              <Route path="/map">
                <TheMap />
              </Route>
              <Route exact path="/users">
                <UsersList username={username} password={password} />
              </Route>
              <Route exact path="/hioids">
                <DevicesList username={username} password={password} />
              </Route>
              <Route exact path="/users/new">
                <CreateUser username={username} password={password} />
              </Route>
              <Route exact path="/devices/new">
                <CreateDevice username={username} password={password} />
              </Route>
              <Route exact path="/devices/:hioid/edit">
                <EditDevice username={username} password={password} />
              </Route>
              <Route exact path="/users/:id/devices/bind">
                <BindDevice username={username} password={password} />
              </Route>
            </Switch>
          </Router>

          <CssBaseline />
        </ThemeProvider>
    </div>
  );
}

export default App;
