import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

import { Link as RouterLink } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import { fabClasses } from "@mui/material";
import { useStyles } from "../utils/spacing";

const UsersList = (props) => {
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (users == []) {
      return;
    }
    fetch("/v1/admin/users", {
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUsers(data);
      })
      .catch((error) => {
        console.log(error);
        history.push("/");
      });
  }, []);

  const UserElement = {
    textAlign: "left",
    overflow: "hidden",
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Paper style={{ width: "100%" }}>
        <Box p={0} m={1}>
          <Box
            p={0}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h1>Users</h1>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Button variant="outlined" component={RouterLink} to="/users/new">
                Create
              </Button>
            </Box>
          </Box>
          <hr/>
          <Grid container>
            {users.map((user, index) => {
              return (
                <>
                  <Grid item xs={1} sm={1} md={1} style={UserElement}>
                    {user.Id}
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} style={UserElement}>
                    {user.Email}
                  </Grid>
                  <Grid item xs={12} sm={5} md={8} style={UserElement}>
                    {user.Description}
                  </Grid>
                  <Grid item xs={3} sm={2} md={1} style={{textAlign:"right"}}>
                    <Button
                      variant="outlined"
                      component={RouterLink}
                      to={`/users/${user.Id}/devices/bind`}
                    >
                      Bind
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={UserElement}>
                    <i style={{ color: "gray" }}>created {user.Created}</i>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} style={UserElement}>
                    <hr />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default UsersList;
