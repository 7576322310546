import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "min-content min-content 1fr",
    height: "100%",
    maxHeight: "100%",
  },
  containerMap: {
    display: "grid",
    gridTemplateColumns: "1fr",
    height: "100%",
    maxHeight: "100%",
  },
  cell: {
    width: "100%",
    margin: "3px",
  },

  root: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  mapContainer: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down('md')]: {
      height: "100%",
    },
  },
  collapseMarginPadding: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
  },
  hideOnSmallScreens: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  showOnSmallScreens: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
