import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { React } from "react";
import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from "@mui/icons-material/Menu";
import { withRouter, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { Link as MaterialLink } from "@mui/material";
import { useStyles as responsiveStyles} from "../utils/spacing";


import { Box, Paper, Grid, Typography, TextField } from "@mui/material";

const logo = {
  maxHeight: "55px",
};

const useStyles = makeStyles((theme) => ({
  logoLink: {
    margin: 0,
    padding: 0,
  },

}));

const Header = (props) => {
  const classes = useStyles();
  const responsive = responsiveStyles(); 
  const location = useLocation();

  return (
    <div className={responsive.hideOnSmallScreens}>
      <AppBar position="static" >
        <Box
          p={0}
          m={0}
          style={{ display: "flex", justifyContent: "flex-start", backgroundColor: "#1d1d26"}}
        >
          <MaterialLink
            to={props.apikey ? "/devices" : props.username ? "/users" : "/"}
            component={Link}
            style={{display: "flex", flexDirection: "column"}}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo-hiotee.gif"}
              style={{ width: 300, maxWidth: "100vw"}}
            />
          </MaterialLink>
          <Toolbar style={{ width: "100%" }}  className={responsive.hideOnSmallScreens}>
            {(props.apikey || props.username) && (
              <Box >
                <Button component={Link} to="/devices" color="inherit">
                  Devices
                </Button>
              </Box>
            )}
            {props.username && (
              <Box>
                <Button component={Link} to="/users" color="inherit">
                  Users
                </Button>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {(props.username ||props.apikey)
               && (
                <Box>
                  <Button style={{}} component={Link} to="/" color="inherit">
                    Logout
                  </Button>
                </Box>
              )}
          </Toolbar>
        </Box>
      </AppBar>
    </div>
  );
};

export default withRouter(Header);
