import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useStyles} from "../utils/spacing";


const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiKey, setApiKey] = useState("");
  const history = useHistory();
  const classes=useStyles();

  const resetCredentials = () => {
    props.setapikey(null);
    props.setcredentials(null, null);
  }

  useEffect(() => {
    resetCredentials();
  }, [])

  const validateApikey = () => {
      props.setapikey(apiKey);
      props.setcredentials(null, null);
      history.push("/devices")
  };

  const validateCredentials = () => {
    props.setcredentials(username, password);
    props.setapikey(null);
    history.push("/users");
  }

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Box m={2}>
          <Paper style={{ minWidth: 300 }}>
            <Box m={2} p={2}>

              <TextField
                id="email"
                label="Username"
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                required
                autoComplete="on"
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
                autoComplete="on"
              />
              <Button onClick={validateCredentials}>Login</Button>

              <hr />
              <TextField
                id="apikey"
                label="API Key"
                margin="normal"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                fullWidth
                required
              />
              <Button
                onClick={validateApikey}
              >
                Login
              </Button>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </div>
  );
};

export default Login;
