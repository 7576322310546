import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useHistory, Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import TheMap from "./TheMap";
import {
  showLat,
  showLng,
  showTimestamp,
  devicesDataToMap,
} from "../utils/format";
import { useStyles } from "../utils/spacing";

const MapAndList = (props) => {
  const classes = useStyles();
  const [count, setCount] = useState("10");

  const ListElement = {
    textAlign: "left",
    overflow: "hidden",
  };

  const container = {
    display: "grid",
    gridTemplateColumns: "min-content min-content 1fr",
    height: "100%",
    maxHeight: "100%",
  };

  const cell = {
    width: "100%",
    margin: "3px",
  };

  const handleSetCount = (event, newCount) => {
    setCount(newCount);
  };

  return (
    <Grid container style={{ width: "100%", height: "100%" }}>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Box
          className={classes.collapseMarginPadding}
          style={{ height: "100%" }}
        >
          <div className={classes.mapContainer}>
            <TheMap track={props.track} data={props.elements.slice(-count)} />
          </div>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={3}
        p={0}
        m={0}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          style={{
            paddingBottom: 0,
            marginBottom: 0,
            paddingTop: 0,
            textAlign: "left",
          }}
          className={classes.collapseMarginPadding}
        >
          <span>Last : </span>
          <ToggleButtonGroup exclusive value={count} onChange={handleSetCount}>
            <ToggleButton value="10" aria-label="left aligned">
              10
            </ToggleButton>
            <ToggleButton value="20" aria-label="centered">
              20
            </ToggleButton>
            <ToggleButton value="50" aria-label="centered">
              50
            </ToggleButton>
            <ToggleButton value="200" aria-label="centered">
              200
            </ToggleButton>
            <ToggleButton value="500" aria-label="centered">
              500
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box
          className={classes.collapseMarginPadding}
          style={{ flexGrow: 1, marginTop: 0, paddingTop: 0 }}
        >
          <div
            style={{
              position: "relative",
              left: 0,
              top: 0,
              height: "100%",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div style={{ position: "absolute" }}>
              <Box style={container}>
                {props.elements.slice(-count).map((element, index) => {
                  return (
                    <>
                      <Box style={cell}>
                        <strong>{element.hioid}</strong>
                      </Box>
                      <Box style={cell}>
                        <MaterialLink component={Link} to={`/devices/${element.hioid}/tracking?track=${element.id}`}>
                          {element.lat};{element.lng}
                        </MaterialLink>
                      </Box>
                      <Box style={cell}>
                        {new Date(element.timestamp * 1000).toUTCString()}
                      </Box>
                    </>
                  );
                })}
              </Box>
            </div>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MapAndList;
