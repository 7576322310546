import React, { useState } from "react";

import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Label } from "@mui/icons-material";
import { useStyles } from "../utils/spacing";

import { useHistory, Link } from "react-router-dom";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const CreateDevice = (props) => {
  const [manufacturer_data, setManufacturer_data] = useState("");
  const classes = useStyles();
  const [type, setType] = useState("IRIDIUM");
  const [identifier, setIdentifier] = useState("IMEI");
  const [status, setStatus] = useState(null);

  const history = useHistory();

  const typeLoopup = {
    IRIDIUM: "IMEI",
    GSM: "IMEI",
    KINEIS: "KINEIS_SERIAL",
    KINEIS_WITH_DOP: "KINEIS_SERIAL",
  };

  const networks = {
    IRIDIUM: "iridium",
    GSM: "gsm",
    KINEIS: "argos",
    KINEIS_WITH_DOP:"argos-dop",
  }

  const createDevice = () => {
    fetch("/v1/admin/core/address/manufacturer", {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
      body: JSON.stringify({
        manufacturer_id: `${identifier}:${manufacturer_data}`,
        manufacturer_data: btoa(manufacturer_data),
        network: networks[type],
      }),
    })
      .then((response) => {
          if(response.status === 200) {
            return response.json();
          } else {
              response.text().then((t)=>{
                  setStatus("Failed : " + t);
              })
              return Promise.reject("Failed")
          }
      })
      .then((response) => {
        console.log(response);
        history.push("/devices");
      })
  };

  return (
    <Box className={classes.root} spacing={0}>
      <Paper style={{ width: "100%" }}>
        <Box p={1} m={1}>
          <Box
            p={0}
            m={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <h1 style={{ margin: 0, padding: 0 }}>Create Device</h1>
          </Box>
          <Box
            p={0}
            m={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <ToggleButtonGroup
              exclusive
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                setIdentifier(typeLoopup[e.target.value]);
              }}
            >
              <ToggleButton value="IRIDIUM" aria-label="left aligned">
                Iridium
              </ToggleButton>
              <ToggleButton value="GSM" aria-label="centered">
                GSM/cellular
              </ToggleButton>
              <ToggleButton value="KINEIS" aria-label="centered">
                Kineis (IDD)
              </ToggleButton>
              <ToggleButton value="KINEIS_WITH_DOP" aria-label="centered">
                Kineis (IDH, with DOP support)
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box
            p={0}
            m={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <FormControl fullWidth="true">
              <TextField
                id="manufacturer_data"
                value={manufacturer_data}
                label={identifier}
                onChange={(e) => setManufacturer_data(e.target.value)}
              />
              <Box
                m={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="outlined" onClick={createDevice}>
                  Create
                </Button>
              </Box>
            </FormControl>
          </Box>
          {status && (
            <Box
              p={0}
              m={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <p>{status}</p>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateDevice;
