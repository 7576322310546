import { Paper, Box } from "@mui/material";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import {
  popupContent,
  popupHead,
  popupText,
  okText,
} from "../utils/popupStyles";
import "./Map.css";
import HourMeter from "./HourMeter";
import * as L from "leaflet";

import MyMarker from "./MyMarker";

import makeStyles from "@mui/styles/makeStyles";

const LeafIcon = L.Icon.extend({
  options: {}
});

const blueIcon = new LeafIcon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
    iconAnchor: [12,41],
}),
greenIcon = new LeafIcon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
    iconAnchor: [12,41],
}),
orangeIcon = new LeafIcon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png",
    iconAnchor: [12,41],
}),
redIcon = new LeafIcon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
    iconAnchor: [12,41],
});

export const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    // [theme.breakpoints.up("sm")]: {
    //   minHeight: "100px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: "max(200px, 50vw)",
    // },
  },
}));

const TheMap = (props) => {
  const classes = useStyles();

  const infofilter = (info)=>{
    const reject = ["hioid", "last", "timestamp", "raw", "CRC", "BCH", "data", "lat", "lng", "id", "bch_data_too_corrupt", "bch_error_count", "soc_percent"];
    return !reject.includes(info);
  }

  const selectIcon = (rssi) => {
    if(rssi === undefined || rssi === null) {
      return blueIcon;
    } else {
      rssi = Math.abs(rssi);
      if(rssi < 70) {
        return greenIcon;
      } else if (rssi > 55 && rssi < 100) {
        return orangeIcon;
      } else {
        return redIcon;
      }
    }
  }

  const overrideName = (name) => {
    const overrides = {
      "vbatt_mV": "Battery (mV) ",
      "soc_percent":"Battery (%) ",
      "mean_tempC": "Temperature (°C)",
      "alert_tempC": "Temp. Alert. (°C)",
      "hour_meter":"Active Time ",
      "timestamp_alert" : "Alert. date ",
      "duration_hours": "Temp. Period (Hours)",
      "ts":"timestamp",
    }
    return overrides[name] || name;
  }

  const valueFormat = (key, value) => {
    if(key === "timestamp_alert") {
      return new Date(
        value * 1000
      ).toUTCString();
    } else {
      return value;
    }
  }


  return (
    <div
      style={{
        position: "relative",
        left: 0,
        top: 0,
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",

          height: "100%",
          width: "100%",
        }}
      >
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
        <script
          src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
          integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
          crossOrigin=""
        ></script>
        <MapContainer
          className={classes.map}
          center={[51.505, -0.09]}
          zoom={3}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.data
            .filter(
              (element) =>
                element.lat !== undefined && element.lng !== undefined && element.lat > -90 && element.lat < 90 && element.lng > -180 && element.lng < 180
            )
            .map((element, index) => {
              return (
                <MyMarker
                  id="index"
                  position={[element.lat, element.lng]}
                  open={element.id == props.track}
                  icon={selectIcon(element.rssi_dBm)}
                >
                  <Popup autoClose={true} className="request-popup">
                    <Paper style={{padding: "3px"}}>
                      <b>{element.hioid}</b>&nbsp;-&nbsp;
                      {/* <br /> */}
                      <b>{new Date(element.timestamp * 1000).toUTCString()}</b>
                      <br />

                      {element["hour_meter"] !== undefined && element["hour_meter"] !== null && 
                      <HourMeter hours={element.hour_meter}></HourMeter>}


                      {Object.keys(element).filter(infofilter).map((key) => {
                        return (
                          <>
                            <b>{overrideName(key)}</b> :{" "}
                            {JSON.stringify(valueFormat(key, element[key])).slice(-30)}
                            <br />
                          </>
                        );
                      })}
                      
                    </Paper>
                  </Popup>
                </MyMarker>
              );
            })}
        </MapContainer>
      </div>
    </div>
  );
};

export default TheMap;
