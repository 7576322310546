import React, { useEffect, useState } from "react";

import { useStyles } from "../utils/spacing";
import { useParams, withRouter, useHistory } from "react-router-dom";

import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Label, SettingsInputAntennaTwoTone } from "@mui/icons-material";

const BindDevice = (props) => {
  const classes = useStyles();
  const [hioid, setHioid] = useState("");
  const [application, setApplication] = useState("");
  const [updateApplication, setUpdateApplication] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!props.username || !props.password) {
      history.push("/");
    }
  }, []);

  const bindDevice = () => {
    let message = {
      hioid: parseInt(hioid),
    };
    if (updateApplication) {
      message.application = application;
    }
    fetch(`/v1/admin/users/${id}/devices`, {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
      body: JSON.stringify(message),
    }).then((response) => {
      if (response.status === 200) {
        setStatus("Success !");
      } else {
        setStatus("Failed : " + response.statusText);
      }
      return response.json();
    });
  };

  return (
    <Box className={classes.root} spacing={0}>
      <Paper style={{ width: "100%" }}>
        <Box p={1} m={1} style={{ textAlign: "left" }}>
          <h3>Bind device to user {id}</h3>
          <FormControl fullWidth="true">
            <TextField
              id="hioid"
              value={hioid}
              type="number"
              label="Device's HIoId"
              onChange={(e) => setHioid(e.target.value)}
            />

            <FormGroup style={{ paddingTop: 8 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    color="default"
                    checked={updateApplication}
                    onChange={(e) => setUpdateApplication(!updateApplication)}
                  />
                }
                label="Set application"
              />
              {updateApplication && (
                <>
                  <p style={{ margin: 0 }}>
                    <strong>Warning:</strong> Binding the application will
                    change application for the device regardless of other bound
                    users
                  </p>
                  <TextField
                    style={{ margin: 0, padding: 0 }}
                    id="application"
                    value={application}
                    label="example: jetski, std-trackAccel, ..."
                    onChange={(e) => setApplication(e.target.value)}
                  />
                </>
              )}
            </FormGroup>

            <Box
              m={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="outlined" onClick={bindDevice}>
                Bind now
              </Button>
            </Box>
          </FormControl>
          <p>{status}</p>
        </Box>
      </Paper>
    </Box>
  );
};

export default withRouter(BindDevice);
