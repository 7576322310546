import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";
import { useHistory, Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import TheMap from "./TheMap";
import MapAndList from "./MapAndList";
import {
  showLat,
  showLng,
  showTimestamp,
  devicesDataToMap,
} from "../utils/format";

import { useStyles } from "../utils/spacing";

const DeviceList = (props) => {
  const [devices, setDevices] = useState([]);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (devices == []) {
      return;
    }
    fetch("/v1/sensors?lastpacket=true", {
      headers: {
        "X-Api-Key": props.apikey,
      },
    })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        let formattedData = formatData(data);
        console.log({formattedData});
        setDevices(formattedData);
        console.log(devices);
      })
      .catch((error) => {
        console.log(error);
        history.push("/");
      });
  }, []);

  const formatData = (input) => {
    return input.map((item) => {
      let result = item;
      let data = item.last?.data;
      if (Array.isArray(data)) {
        return Object.assign(result, data[0]);
      } else {
        if(item["mesr"] !== undefined && item["mesr"] != null){
          return item["mesr"];
        }else {
          return Object.assign(result, data);
        }
      }
    });
  };

  return (
    <Box className={classes.root} spacing={0}>
      <Paper style={{ width: "100%", height: "100%"}}>
        <MapAndList elements={devices} title="Devices"/>
      </Paper>
    </Box>
  );
};

export default DeviceList;
