import React, { useState } from "react";

import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Label } from "@mui/icons-material";
import { useStyles } from "../utils/spacing";

const CreateUser = (props) => {
  const [email, setemail] = useState("");
  const [description, setDescription] = useState("");
  const [apikey, setApikey] = useState("");
  const classes = useStyles();

  const createUser = () => {
    fetch("/v1/admin/users", {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
      body: JSON.stringify({
        description: description,
        email: email,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setApikey(response.api_key);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box className={classes.root} spacing={0}>
      <Paper style={{ width: "100%" }}>
        <Box p={1} m={1}>
          <Box
            p={0}
            m={0}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <h1 style={{ margin: 0, padding: 0 }}>Create User</h1>
          </Box>

          <FormControl fullWidth="true">
            <TextField
              id="email"
              value={email}
              label="Username or email"
              onChange={(e) => setemail(e.target.value)}
            />
            <TextField
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows="3"
              label="Description"
            />
            <Box
              m={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="outlined" onClick={createUser}>
                Create
              </Button>
            </Box>
          </FormControl>
          {apikey !== "" && (
            <>
              <Box>
                <Box
                  p={0}
                  m={0}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <h1 style={{ margin: 0, padding: 0 }}>User credentials</h1>
                </Box>
                <Box p={0} m={0}>
                  <Box
                    p={0}
                    m={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <InputLabel htmlFor="apikey">
                      User Created. You MUST copy this api key, It will be
                      provided only once !
                    </InputLabel>
                  </Box>
                  <Box
                    p={0}
                    m={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth="true"
                      disabled
                      multiline
                      type="text"
                      id="apikey"
                      value={apikey}
                    ></TextField>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateUser;
