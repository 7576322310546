import { createTheme} from "@mui/material/styles";
import { orange, green, grey } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        mode: "dark",
        // primary: {
            // main: "#303030",
            // light: "#ffffff",
            // ok: green[500],
            // warning: orange[500],
            // alert: '#ff0400',
        // },
        // secondary: {
        //     main: "#303030",
        //     light: "#ffffff",
        //     ok: green[500],
        //     warning: orange[500],
        //     alert: '#ff0400',
        // }
    },
    spacing: 8
});

export default theme;
