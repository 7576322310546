import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

import { Link as RouterLink } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import { fabClasses } from "@mui/material";
import { useStyles } from "../utils/spacing";

const DevicesList = (props) => {
  const history = useHistory();
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    if (devices == []) {
      return;
    }
    fetch("/v1/admin/devices", {
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setDevices(data);
      })
      .catch((error) => {
        console.log(error);
        history.push("/");
      });
  }, []);

  const DeviceElement = {
    textAlign: "left",
    overflow: "hidden",
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Paper style={{ width: "100%" }}>
        <Box p={0} m={1}>
          <Box
            p={0}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
              <h1>{devices.length} Devices</h1>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  variant="outlined"
                  component={RouterLink}
                  to="/devices/new"
                >
                  Create
                </Button>
              </Box>
            </Box>

            <Grid container>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <b>hioid</b>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <b>manufacturer_data</b>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <b>manufacturer_data (decoded)</b>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <b>application</b>
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <b>network</b>
              </Grid>

              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <b></b>
              </Grid>
         
              
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={DeviceElement}
                className={classes.hideOnSmallScreens}
              >
                <hr />
              </Grid>

              {devices.map((device, index) => {
                return (
                  <>
                    <Grid item xs={12} sm={1} md={1} style={DeviceElement}>
                      <b className={classes.showOnSmallScreens}>hioid : </b>
                      {device.hioid}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} style={DeviceElement}>
                      <b className={classes.showOnSmallScreens}>
                        manufac.dat. :{" "}
                      </b>
                      {device.manufacturer_data}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} style={DeviceElement}>
                      <b className={classes.showOnSmallScreens}>(decoded) : </b>
                      {atob(device.manufacturer_data).match(/^[0-9a-z]+$/)
                        ? atob(device.manufacturer_data)
                        : "(binary)"}
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} style={DeviceElement}>
                      <b className={classes.showOnSmallScreens}>
                        application :{" "}
                      </b>
                      {device.application}
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} style={DeviceElement}>
                      <b className={classes.showOnSmallScreens}>network : </b>

                      {device.network}
                    </Grid>
                    <Grid item xs={12} sm={2} md={1} style={{textAlign:"right"}}>
                      <Button
                        variant="outlined"
                        component={RouterLink}
                        to={`/devices/${device.hioid}/edit`}
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={DeviceElement}>
                      <hr />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default DevicesList;
