import React, { useState, useEffect} from "react";

import {
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Label } from "@mui/icons-material";
import { useStyles } from "../utils/spacing";

import { useParams, useHistory, Link } from "react-router-dom";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const CreateDevice = (props) => {
  const [application, setApplication] = useState("");
  const [network, setNetwork] = useState("");
  const classes = useStyles();
  const [type, setType] = useState("IRIDIUM");
  const [identifier, setIdentifier] = useState("IMEI");
  const [status, setStatus] = useState(null);
  const { hioid } = useParams();


  const history = useHistory();

  const typeLoopup = {
    IRIDIUM: "IMEI",
    GSM: "IMEI",
    KINEIS: "KINEIS_SERIAL",
    KINEIS_WITH_DOP: "KINEIS_SERIAL",
  };

  const networks = {
    IRIDIUM: "iridium",
    GSM: "gsm",
    KINEIS: "argos",
    KINEIS_WITH_DOP:"argos-dop",
  }

  const updateDevice = () => {
    fetch(`/v1/admin/core/devices/${hioid}/network`, {
      method: "PUT",
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
      body: JSON.stringify({
        network: network,
      })
    })
    .then((response)=>{
      if(response.status === 200) {
        // update application here
        fetch(`/v1/admin/core/devices/${hioid}/application`, {
          method: "PUT",
          headers: {
            Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
          },
          body: JSON.stringify({
            application: application,
          })
        }).then((response)=>{
          if(response.status === 200) {
            setStatus("Success !");
          }
        })
        .catch((e)=>{
          setStatus(`Failed to update device : ${e}`);
        })
      }
    })
    .catch((e)=>{
      setStatus(`Failed to update device : ${e}`);
    })
  }

  const fetchDevice = () => {
    fetch(`/v1/admin/core/devices/${hioid}/manufacturer_data`, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa(`${props.username}:${props.password}`),
      },
    })
      .then((response) => {
          if(response.status === 200) {
            return response.json();
          } else {
              response.text().then((t)=>{
                  // setStatus("Failed : " + t);
              })
              return Promise.reject("Failed")
          }
      })
      .then((response) => {
        console.log(response);
        setApplication(response.application);
        setNetwork(response.network);
      })
  };

  useEffect(() => {
    fetchDevice();
  }, []);

  return (
    <Box className={classes.root} spacing={0}>
      <Paper style={{ width: "100%" }}>
        <Box p={1} m={1}>
          <Box
            p={0}
            m={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <h1 style={{ margin: 0, padding: 0 }}>Edit #{hioid}</h1>
          </Box>
          <Box
            p={0}
            m={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
        
          </Box>
          <Box
            p={0}
            m={1}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <FormControl fullWidth="true">
              <TextField
                id="application"
                value={application}
                label="application"
                onChange={(e) => setApplication(e.target.value)}
              />
              <br/>
              <TextField
                id="network"
                value={network}
                label="network"
                onChange={(e) => setNetwork(e.target.value)}
              />
              <Box
                m={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="outlined" onClick={updateDevice}>
                  Update
                </Button>
              </Box>
            </FormControl>
          </Box>
          {status && (
            <Box
              p={0}
              m={1}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <p>{status}</p>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateDevice;
