import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import "./HourMeter.css";

export const useStyles = makeStyles((theme) => ({}));

const HourMeter = (props) => {
  const classes = useStyles();

  return (
    <div>
      <div class="container">
        <div class="hourmeter">
          <span>{Math.trunc(props.hours).toString().padStart(5, '0').at(0)}</span>
          <span>{Math.trunc(props.hours).toString().padStart(5, '0').at(1)}</span>
          <span>{Math.trunc(props.hours).toString().padStart(5, '0').at(2)}</span>
          <span>{Math.trunc(props.hours).toString().padStart(5, '0').at(3)}</span>
          <span>{Math.trunc(props.hours).toString().padStart(5, '0').at(4)}</span>
          <span class="hourmeterquarter">
          {(props.hours - Math.trunc(props.hours)) * 4 === 0 && <>&#x00bb;</>}
          {(props.hours - Math.trunc(props.hours)) * 4 === 1 && <>&#x00bc;</>}
          {(props.hours - Math.trunc(props.hours)) * 4 === 2 && <>&#x00bd;</>}
          {(props.hours - Math.trunc(props.hours)) * 4 === 3 && <>&#x00be;</>}
             
          </span>
          <b style={{ marginRight: "10px" }}>h</b>
        </div>
      </div>
    </div>
  );
};

export default HourMeter;
